import { getBrowserResolutionBreakpointString } from './services/analytics';

export const getBrandName = (): string => {
  return process.env.REACT_APP_WEBSITE_BRAND === 'vw5' ||
    !process.env.REACT_APP_WEBSITE_BRAND
    ? 'vw'
    : process.env.REACT_APP_WEBSITE_BRAND;
};

export const brandNames: { [index: string]: string } = {
  audi: 'Audi',
  seat: 'SEAT',
  skoda: 'ŠKODA',
  vw: 'Volkswagen',
  vw6: 'Volkswagen',
  vwcv: 'Volkswagen',
  vwfs: 'Volkswagen',
  cupra: 'CUPRA',
};

export const ANALYTICS_DEFAULTS = {
  core: {
    /*
    Please put here the stage (DEV, INT, CONS, PROD) on which this site is
    */
    stagingEnvironment: process.env.REACT_APP_STAGE?.toUpperCase(),

    dataLayerVersion: '2.8',

    pageInfo: {
      /*
      The values for pagename are defined in tracking specification
      document.
      */
      pageName: null,

      /*
      The device type the website is intended for.
      Type: Enum
      Mobile phone
      Tablet
      Desktop
      all
      */
      intendedCustomerDeviceType: 'all',

      /*
      Version or release of the site. Constant value of your choice
      which identifies best this current application version.
      */
      version: '1.0',

      /*
      Release date of this site as a constant value. Format YYYY-MM-DD
      */
      releaseDate: '2021-06-15',

      /*
      Current display language.
      Type: Enum
      ISO-639-2; lower case two letter code
      Hint: Visitor can change the language while his visit (e.g. in
      Switzerland).
      */
      language: 'pt',

      /*
      Type: Enum
      The country name of the market this site is serving
      (e.g. DE: W&I -> DE, UK: S&I -> UK).
      ISO-3166-1-alpha-2; upper case two letter code
      */
      market: 'PT',

      /*
      Type: Enum
      Name of responsible department (DU, local-DE, local-UK)
      */
      publisher: 'DU',
    },
    category: {
      /*
      Main category of this site.
      Type: Enum
      Banking
      Charge & fuel
      Customer portal
      Digital renewal
      Financing
      Fleet
      fob
      Insurance
      Leasing
      Local customer portal
      Local portal
      Local public site
      NBW
      Rental
      Service
      */
      primaryCategory: 'Insurance',

      /*
      Please further describe your journey/site *if necessary*. The
      product owner can choose a unique name.
      Examples:
      Champions Club
      OperateLease
      Short term insurance
      Service and inspection
      AutoCredit & ClassicCredit
      */
      secondaryCategory: 'Motor insurance',

      /*
      Further sub-categories of the product, e.g. deductibles in an
      insurance contract which the customer can choose
      */
      productVariants: [
        {
          name: '',
        },
      ],

      /*
      If your journey splits in sub processes, standalone, checkout site,
      app, ...
      Type: Enum
      app
      checkoutSite
      standalone
      */
      siteType: 'standalone',

      /*
      Classification of the result which should be achieved by the journey
      Type: Enum
      Lead
      Online contract
      */
      maturityLevel: 'Online contract',
    },
    attributes: {
      /*
      Type: Enum
      customer-facing-product-journey
      customer-facing-journey
      dealer-facing-product-journey
      dealer-facing-journey
      after-sales-journey
      */
      journeyType: 'customer-facing-product-journey',

      /*
      If your page contains expandables, which once expanded do not
      change the URL. Example: On page personal data, there are
      expandables for address, account details, ... The values are
      specified in the corresponding specification document.
      */
      viewChange: null,

      /*
      Refers to the branding of the site.

      Never include design versions here: This should not be changed to
      vw6 when a new design is released. The brand is still vw.
      vwcv refers to VW Commercial Vehicles. BFF’s data model supplies
      vwn, but we expect vwcv.

      @Developers: Please fill in the correct value here based on the
      site's
      branding

      Type: Enum
      Audi
      Bentley
      Cupra
      Ducati
      Lamborghini
      MAN
      Porsche
      Scania
      Seat
      Skoda
      VW
      VWCV
      VWFS
      */
      brand: getBrandName(),
    },
  },
  /*
  In case of any error situations (mainly standard errors like 401, 404, ...
  and in context of forms) an error code (if available) and the error message
  (if possible a shortened version) are placed here. If available the causing
  URL (e.g. the wrong URL in case of 404, the referring URL in case 401, ...)
  should be placed here, too.
  */
  error: {
    // Error code of application exceptions or caught errors
    errorCode: null,

    // Error message for caught errors with available message
    errorMessage: null,

    // Full URL which caused the error
    errorCausingURL: null,
  },

  /*
  This structure is filled as soon as the user provides information
  regarding any product.

  Please note that this structure is an array structure. If the customer can
  bundle his contract (i.e. get a leasing contract, a service and maintenance
  package, and a tire package, this array should have three entries. Same is
  e.g. in Customer portal if customer has more than one product.
  */
  product: [
    {
      /*
    The main category of the current contract.
    Type: Enum
    Finance
    Insurance
    Leasing
    Service
    Other
    */
      category: 'Insurance',

      /*
    The name of the product from the given list. If you cannot find a
    suitable entry for the product sold in the journey, please reach out
    to the web analytics team. Please do not add any products to this list
    on your own.

    Type: Enum
    AutoCredit
    CarSharingProtection
    ChargeAndFuel
    ClassicCredit
    Credit
    CreditProtectionInsurance
    LeasingRateInsurance
    DigitalRenewal
    ExtendedWarranty
    FinanceLease
    GuaranteedAssetProtection
    MotorInsurance
    OperateLease
    RentalCarProtection
    ServiceInspection
    ServiceLimitedMaintenance
    TestDriveProtection
    ThirdPartyProtection
    UsedCarWarranty
    TirePackage
    */
      name: 'MotorInsurance',

      /*
    Further sub-categories of the product, e.g. deductibles in an insurance
    contract which the customer has chosen
    */
      productVariants: [],

      /*
    Additional options which the customer has chosen
    E.g. Glass breakage insurance, credit rate insurance, tyre insurance
    */
      productAddons: [],

      attributes: {
        /*
      Specifies the type of complete sales process

      Type: Enum
      Hybrid sales
      Online sales
      Partner sales
      */
        typeOfSale: 'Online sales',

        /*
      Unit for payment frequency
      ( = financialProduct.paymentDetails.paymentFrequency).
      Type: Enum
      single payment
      weekly payment
      biweekly payment
      monthly payment
      bimonthly payment
      quarterly payment
      biannual payment
      annual payment
      */
        paymentFrequency: null,

        /*
      The currency unit used for displayed prices (regular the local
      currency). The value is taken from ISO-Code 4217.
      */
        currency: 'EUR',

        /*
      E.g. monthly payment for insurance or leasing contract or an
      installment. Please provide exact (float) value
      Type: Float
      The currency is the local currency.
      The value is stored without currency unit.
      */
        recurringPayment: null,

        /*
      Planned duration of contract; please use value -1 if indefinite
      Type: Integer
      */
        duration: 12,

        /*
      Unit for planned duration of contract.
      Type: Enum
      WEEK
      TWO-WEEKS
      MONTH
      TWO-MONTHS
      QUARTER
      SIX-MONTHS
      YEAR
      */
        durationUnit: 'MONTH',

        /*
      The start date of contract in focus ( = offers[].startDate).
      Type: Date (YYYY-MM-DD)
      */
        startDateOfContract: null,

        /*
      The end date of contract in focus ( = offers[].startDate) + 1 year).
      Type: Date (YYYY-MM-DD)
      */
        endDateOfContract: null,

        /*
      The type of payment ( = customerData.paymentData.method).

      Type: Enum

      Automatic Bank Transfer
      Credit Card
      Direct Deposit
      E-Wallet
      Mobile Payment
      */
        paymentType: null,

        /*
      The final amount customer has to pay to fulfill the contract.
      Type: Float
      The currency is the local currency.
      The value is stored without currency unit.
      */
        contractAmount: null,

        /*
      The deductible that is part of the contract
      ( = offers[].variants[].deductible).
      Type: Float
      The currency is the local currency.
      The value is stored without currency unit.
      */
        deductible: null,

        /*
      Addons the customer can configure.
      Type: String
      Example:
      name: Home delivery
      additionalInformation: null
      name: Pick up at dealer
      additionalInformation: Dealer zip 65473
      name: Additional mileage
      additionalInformation: 25000
      */
        addOns: [
          {
            name: null,
            additionalInformation: null,
          },
        ],
      },
      // Aligned with BFF data model!
      vehicleModel: [
        {
          /*
      The manufacturer of the current vehicle
      ( = vehicleData.model.manufacturer).
      Type: Enum
      audi
      bentley
      cupra
      ducati
      man
      porsche
      scania
      seat
      skoda
      vw
      vwcv
      vwfs
      */
          manufacturer: null,

          /*
      The main name of the current car ( = vehicleData.model.name).

      Type: string, for example
      A3
      Kodiaq
      XC60
      Auris
      Golf
      */
          name: null,

          /*
      Long description of the vehicle model
      ( = vehicleData.model.description)
      Type: String
      Example:
      Golf GTI Performance
      A3 Sportback 1.5 TFSI Black Edition
      */
          descriptionLong: null,

          /*
      Describes the body type of the vehicle
      ( = vehicleData.model.bodyType).
      Type: Enum
      CONVERTIBLE
      COUPE
      CROSSOVER
      CABRIO
      LUXURY CAR
      SEDAN
      SPORTS CAR
      SUV
      TRUCK
      VAN
      WAGON
      */
          bodyType: null,

          /*
      The date when the vehicle has been registered first (In german
      'Tag der Erstzulassung') ( = vehicleData.initialRegistrationDate).

      This value is of special importance to be tracked correctly as it
      serves as a measure for customer loyalty. Tracking this value
      correctly is of high importance for our management.
      Type: String
      Format: YYYY-MM-DD
      */
          initialRegistrationDate: null,

          /*
      Local currency
      ISO-4217 alphabetic code upper case letters
      Example: PLN
      */
          currency: null,

          /*
      The final vehicle price (local currency)
      ( = vehicleData.marketValueAmount).
      The unit is not part of the value.
      Type: Integer
      Unit local currency
      Original value: 15388 PLN
      Data layer value: 15388
      */
          endPrice_localCurrency: null,

          /*
      The type of usage

      Enum:
      Private
      Business
      Both
      */
          typeOfUse: null,

          /*
      The condition of the vehicle ( = vehicleData.isUsed).
      E.g.
      New car
      Used car
      */
          condition: null,

          engine: {
            /*
        The engines fuel type of the vehicle
        ( = vehicleData.model.engine.fuelType)

        Enum:
        CNG
        Diesel
        Electric
        Hybrid
        Hydrogen
        LPG
        Petrol
        */
            fuelType: null,

            /*
        The emission value of the engine.
        Format: <nnn> g/km
        */
            emission: null,

            /*
        The engines power in both units: PS and kW. E.g.
        115 PS (84 kW)
        ( = vehicleData.model.engine.power +
            vehicleData.model.engine.powerUnit = HORSEPOWER)
        */
            enginePower: null,
          },
        },
      ],
    },
  ],
  design: {
    /*
    For responsive design: Thresholds when design changes. The values are
    the Bronson breakpoints:
    xxl (browser tab width >= 1920 px)
    xl    (browser tab width >= 1600 px)
    l     (browser tab width >= 1280 px)
    m     (browser tab width >=    960 px)
    s     (browser tab width >=    720 px)
    xs    (browser tab width >=    480 px)
    Type: string
    */
    browserResolutionBreakpoint: getBrowserResolutionBreakpointString(
      window.innerWidth
    ),
  },
  customerData: {
    /*
    Indicates whether the user is currently logged in or not
    Type: Boolean
    */
    loginStatus: false,

    /*
    The user group the customer belongs to. Specified values are:
    - private
    - private (buyer = driver)
    - private (buyer <> driver)
    - business (small)
    - business (corporate)
    - business
    (the latter applies if there is no differentiation according to
    company size)
    */
    loggedInUserGroup: null,

    /*
    The year the customer was born ( = customerData.dateOfBirth).

    Format: YYYY
    */
    yearOfBirth: null,

    /*
    The gender of the customer ( = customerData.gender)
    Type: Enum
    MALE
    FEMALE
    */
    gender: null,
    address: {
      /* If the customer provides multiple addresses, we expect the zip
      code of latest address here ( = customerData.addresses[].zipCode).
      */
      zipCode: null,
    },
  },

  // Aligned with BFF data model!
  dataPrivacyStatement: {
    /*
    If the customer allows a contact via postal the value is Postal.
    When distinguishing between contact partners please use the
    descriptive term as Suffix (enclosed in round brackets).
    Example:
    Postal
    Postal (VWFS)
    Postal (Business partner)
    Type: String
    */
    allowPostalAds: null,

    /*
    If the customer allows a contact via phone the value is Phone.
    When distinguishing between contact partners please use the
    descriptive term as Suffix (enclosed in round brackets).
    Example:
    Phone
    Phone (VWFS)
    Phone (Business partner)
    Type: String
    */
    allowPhoneAds: null,

    /*
    If the customer allows a contact via Electronic channels the value is
     Electronic. When distinguishing between contact partners please use
    the descriptive term as Suffix (enclosed in round brackets).
    Example:
    Electronic
    Electronic (VWFS)
    Electronic (Business partner)
    Type: String
    */
    allowElectronicAds: null,

    /*
    If the customer allows a contact via mail the value is Mail.
    When distinguishing between contact partners please use the
    descriptive term as Suffix (enclosed in round brackets).
    Example:
    Mail
    Mail (VWFS)
    Mail (Business partner)
    Type: String
    */
    allowMailAds: null,

    /*
    If the customer allows a delivery of Invoices via electronic
    applications the value is Electronic invoices.
    Type: String
    */
    allowElectronicInvoices: null,
  },

  /*
  Together with the PO or BA we map our standard events to your journey and
  provide you a specification document with an annotated version of the
  journey. Please fill use the values specified in this document.
  */
  eventInfo: [
    {
      /*
    The type of event which occurred. This information describes the
    context in which the current event occurred. The values are
    specified in the corresponding specification document.
    */
      eventType: null,

      /*
    The event which occurred. The values are specified in the
    corresponding specification document.
    */
      eventAction: null,

      /*
    If a link or button triggers this event, please provide the target
    url of this link.
    */
      eventTargetURL: null,

      /*
    If additional information in context of an interaction element
    is needed. The values are specified in the corresponding
    specification document.
    */

      linkInformation: null,
    },
  ],

  /* To indicate which kind of data request is tracked the variable
     event gets the corresponding value as specified in specification
     (previously it was the parameter for Adobe function _satellite.track()):
     - page
     - interaction
     - download
     - exit

      IMPORTANT: This variable has to get its value as the last variable.
      Setting this variable starts the processing in Adobe Launch as
      the previously used function _satellite.track().
  */

  event: null,
};
