import { RootState } from '..';
import { StorefrontData } from './types';

export const FETCH_STOREFRONT_PENDING = 'storefront//FETCH_STOREFRONT_PENDING';
export const FETCH_STOREFRONT_DONE = 'storefront//FETCH_STOREFRONT_DONE';
export const FETCH_STOREFRONT_ERROR = 'storefront//FETCH_STOREFRONT_ERROR';

export function pending() {
  return {
    type: FETCH_STOREFRONT_PENDING,
    payload: {},
  };
}

export function done(data: any) {
  return {
    type: FETCH_STOREFRONT_DONE,
    payload: {
      data,
    },
  };
}

export function error(err: any) {
  return {
    type: FETCH_STOREFRONT_ERROR,
    payload: {
      err,
    },
  };
}

export function setStorefrontData(data: any) {
  return {
    type: FETCH_STOREFRONT_DONE,
    payload: data,
  };
}

export function getStorefrontData(store: RootState): StorefrontData {
  return store.storefront.data;
}

const initialState = {
  data: {},
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case FETCH_STOREFRONT_PENDING:
      return {
        ...state,
        data: {},
        pending: true,
        error: null,
      };

    case FETCH_STOREFRONT_DONE:
      return {
        ...state,
        data: action.payload,
        pending: false,
        error: null,
      };
    case FETCH_STOREFRONT_ERROR:
      return {
        ...state,
        data: {},
        pending: false,
        error: true,
      };

    default:
      return state;
  }
}
