import { RootState } from '..';

export const SET = 'session//SET';

export interface Session {
  token: string;
  id: string;
}
export interface SessionAction {
  type: string;
  payload: Session;
}

export interface SessionState {
  data: Partial<Session>;
}

export function setSession(data: Session): SessionAction {
  return {
    type: SET,
    payload: data,
  };
}

export function getSession(store: RootState): Session {
  return store.session.data;
}

const initialState = {
  data: { token: '' },
};

export default function (
  state: SessionState = initialState,
  action: SessionAction
): SessionState {
  switch (action.type) {
    case SET:
      return {
        ...state,
        data: action.payload,
      };

    default:
      return state;
  }
}
