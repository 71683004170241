/* eslint-disable no-unused-vars */
import get from 'lodash/get';

import { FormikValues } from 'formik';

import { RootState } from '..';
import {
  VehicleDetailsFormValues,
  CustomerDetailsFormValues,
  ProductDetailsFormValues,
  PurchaseDetailsFormValues,
  CompletionDetailsFormValues,
  ActiveSCDetailsFormValues,
} from './types';

export enum ActionTypes {
  SET_APPLICATION_DATA = 'applicationData//SET_APPLICATION_DATA',
  RESET_APPLICATION_DATA = 'applicationData//RESET_APPLICATION_DATA',
}

export interface ApplicationDataState {
  data: Partial<{
    vehicleDetails: VehicleDetailsFormValues;
    activeSCDetails: ActiveSCDetailsFormValues;
    customerDetails: CustomerDetailsFormValues;
    productDetails: ProductDetailsFormValues;
    subscriptionDetails: PurchaseDetailsFormValues;
    completionDetails: CompletionDetailsFormValues;
  }>;
}

interface PayloadData {
  step: string;
  applicationData: ApplicationDataTypes;
}

interface StepDataAction {
  type: ActionTypes.SET_APPLICATION_DATA;
  payload: PayloadData;
}

interface ResetFormAction {
  type: ActionTypes.RESET_APPLICATION_DATA;
}

type StepDataActionsType = StepDataAction | ResetFormAction;
export type ApplicationDataTypes =
  | FormikValues
  | VehicleDetailsFormValues
  | ActiveSCDetailsFormValues
  | CustomerDetailsFormValues
  | ProductDetailsFormValues
  | PurchaseDetailsFormValues
  | CompletionDetailsFormValues;

export function setStepData(
  step: string,
  applicationData: ApplicationDataTypes
): StepDataAction {
  return {
    type: ActionTypes.SET_APPLICATION_DATA,
    payload: {
      step,
      applicationData,
    },
  };
}

export function resetFormData(): ResetFormAction {
  return {
    type: ActionTypes.RESET_APPLICATION_DATA,
  };
}

export const getStepData = (
  state: RootState,
  step: string
): ApplicationDataTypes =>
  get(state.applicationData.data, `${step}`) as ApplicationDataTypes;

const initialState = {
  data: {},
};

export default function (
  state = initialState,
  action: StepDataActionsType
): ApplicationDataState {
  switch (action.type) {
    case ActionTypes.RESET_APPLICATION_DATA:
      return {
        ...state,
        data: {},
      };
    case ActionTypes.SET_APPLICATION_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.step]: action.payload.applicationData,
        },
      };
    default:
      return state;
  }
}
