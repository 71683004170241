import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import * as React from 'react';
import ReactDOM from 'react-dom';
import { Router, withRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { Spinner } from '@vwfs-its/bronson-react';

import initI18n from './services/i18n';
import { history } from './services/routing';
import { store } from './services/redux';
import { getBrandName } from './config';

const ConnectedApp = React.lazy(() => {
  return import('./App');
});

const PositionRestore = withRouter(
  (props: { location: { pathname: string; hash: string } }) => {
    const [location, setLocation] = React.useState<string>(
      props.location.pathname
    );
    if (location !== props.location.pathname) {
      setLocation(props.location.pathname);
      if (props.location.hash && props.location.hash !== '') {
        setTimeout(() => {
          const id = props.location.hash.replace('#', '');
          const element = document.getElementById(id);
          if (element) element.scrollIntoView({ behavior: 'smooth' });
        }, 0);
      } else {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      }
    }
    return null;
  }
);

const Init: React.FC = () => {
  const [loaded, setLoaded] = React.useState(false);
  React.useEffect(() => {
    initI18n(getBrandName());
    setLoaded(true);
  }, []);
  return loaded ? (
    <React.Suspense fallback={<Spinner fullPage />}>
      <Provider store={store}>
        <Router history={history}>
          <PositionRestore />
          <ConnectedApp />
        </Router>
      </Provider>
    </React.Suspense>
  ) : null;
};

function render(): void {
  const rootElement = document.getElementById('root');
  ReactDOM.render(<Init />, rootElement);
}

declare let module: { hot: any };

if (module.hot) {
  module.hot.accept(render);
}

render();
