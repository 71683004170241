import { createStore, combineReducers, Store, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import applicationDataReducer, {
  ApplicationDataState,
} from './features/applicationData.duck';

import dataModelsReducer, { DataModelsState } from './features/dataModels.duck';
import storefrontReducer from './features/storefront.duck';
import sessionReducer from './features/session.duck';

export interface AppStore {
  applicationData: ApplicationDataState;
  dataModels: DataModelsState;
  storefront: any;
  session: any;
}

const rootReducer = combineReducers<AppStore>({
  applicationData: applicationDataReducer,
  dataModels: dataModelsReducer,
  storefront: storefrontReducer,
  session: sessionReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

const configureStore = (): Store<AppStore> => {
  const middlewares = [thunk];
  const middlewaresEnhancer = applyMiddleware(...middlewares);

  const composeEnhancer = composeWithDevTools({})(middlewaresEnhancer);

  const store = createStore(rootReducer, undefined, composeEnhancer);
  return store;
};

const store = configureStore();

export { store };
