/* eslint-disable no-unused-vars */
import { RootState } from '..';
import { DataModels } from './types';

export enum ActionTypes {
  SET_DATA_MODELS = 'dataModel//SET_DATA_MODELS',
}

export interface DataModelsState {
  data: Partial<DataModels>;
}

interface PayloadData {
  dataModels: DataModels;
}

interface DataModelsAction {
  type: ActionTypes.SET_DATA_MODELS;
  payload: PayloadData;
}

export function setDataModels(data: DataModels) {
  return {
    type: ActionTypes.SET_DATA_MODELS,
    payload: data,
  };
}

export function getDataModels(state: RootState) {
  return state.dataModels.data;
}

const initialState = {
  data: {},
};

export default function (state = initialState, action: DataModelsAction) {
  switch (action.type) {
    case ActionTypes.SET_DATA_MODELS:
      return {
        ...state,
        data: action.payload,
      };

    default:
      return state;
  }
}
