import { merge, isEmpty } from 'lodash';

/**
 * This service is a bridge between the application and whatever analytics
 * software is used.
 *
 * By defining the REACT_APP_ANALYTICS environment variable we are able to
 * either export the real or mocked implementation, so that from the application
 * point of view nothing changes and it can continue calling the analytics logic
 * as per usually.
 */

interface AnalyticsObj {
  core?: {
    stagingEnvironment?: string | null;
    dataLayerVersion?: string | null;
    pageInfo?: {
      pageName?: string | null;
      intendedCustomerDeviceType?: string | null;
      version?: string | null;
      releaseDate?: string | null;
      language?: string | null;
      market?: string | null;
      publisher?: string | null;
    };
    category?: {
      primaryCategory?: string | null;
      secondaryCategory?: string | null;
      productVariants: {
        name?: string | null;
      }[];
      siteType?: string | null;
      maturityLevel?: string | null;
    };
    attributes?: {
      journeyType?: string | null;
      viewChange?: string | null;
      brand?: string | null;
    };
  };
  error?: {
    errorCode?: string | null;
    errorMessage?: string | null;
    errorCausingURL?: string | null;
  };
  product?: {
    name?: string | null;
    category?: string | null;
    productVariants?: string[];
    productAddons?: (string | undefined)[];
    attributes?: {
      typeOfSale?: string | null;
      paymentFrequency?: string | null;
      currency?: string | null;
      recurringPayment?: number | null;
      duration?: number | null;
      durationUnit?: string | null;
      startDateOfContract?: string | null;
      endDateOfContract?: string | null;
      paymentType?: string | null;
      contractAmount?: number | null;
      deductible?: string | null;
      addOns: {
        name?: string | null;
        additionalInformation?: string | null;
      }[];
    };
    vehicleModel?: {
      manufacturer?: string | null;
      name?: string | null;
      descriptionLong?: string | null;
      bodyType?: string | null;
      initialRegistrationDate?: string | null;
      currency?: string | null;
      // eslint-disable-next-line camelcase
      endPrice_localCurrency?: string | null;
      typeOfUse?: string | null;
      condition?: string | null;
      engine?: {
        fuelType?: string | null;
        emission?: string | null;
        enginePower?: string | null;
      };
    }[];
  }[];
  design?: {
    browserResolutionBreakpoint?: string | null;
  };
  customerData?: {
    loginStatus?: boolean | null;
    loggedInUserGroup?: string | null;
    yearOfBirth?: string | null;
    gender?: string | null;
    address?: {
      zipCode?: string | null;
    };
  };
  dataPrivacyStatement?: {
    allowPostalAds?: string | null;
    allowPhoneAds?: string | null;
    allowElectronicAds?: string | null;
    allowMailAds?: string | null;
    allowElectronicInvoices?: string | null;
  };
  eventInfo?: {
    eventType?: string | null;
    eventAction?: string | null;
    eventTargetURL?: string | null;
    linkInformation?: string | null;
  }[];
  event?: string | null;
}

declare global {
  interface Window {
    // eslint-disable-next-line camelcase
    adobeDataLayer: AnalyticsObj[];
  }
}

window.adobeDataLayer = window.adobeDataLayer || [];

export const getBrowserResolutionBreakpointString = (width: number) => {
  if (width <= 480) return 'xs';
  // eslint-disable-next-line no-else-return
  else if (width > 480 && width <= 720) return 's';
  // eslint-disable-next-line no-else-return
  else if (width > 720 && width <= 960) return 'm';
  // eslint-disable-next-line no-else-return
  else if (width > 960 && width <= 1280) return 'l';
  // eslint-disable-next-line no-else-return
  else if (width > 1280 && width <= 1600) return 'xl';
  // eslint-disable-next-line no-else-return
  else return 'xxl';
};

const checkAndRemoveEmptyProps = (obj: any) => {
  let result = true;
  // eslint-disable-next-line no-shadow
  const areEmptyProps = (obj: any) => {
    const newObj = Array.isArray(obj) ? [] : {};
    Object.keys(obj).forEach((key) => {
      if (obj[key] === Object(obj[key]) && !isEmpty(obj[key])) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        newObj[key] = areEmptyProps(obj[key]);
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      else if (
        obj[key] === undefined ||
        obj[key] === null ||
        Object.keys(obj[key].toString()).length === 0
      ) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        result = true;
      }
    });
    return newObj;
  };
  // eslint-disable-next-line no-shadow
  const removeEmptyProps = (obj: any) => {
    const newObj = Array.isArray(obj) ? [] : {};
    Object.keys(obj).forEach((key) => {
      if (obj[key] === Object(obj[key]) && !isEmpty(obj[key])) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        newObj[key] = removeEmptyProps(obj[key]);
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      else if (
        obj[key] !== undefined &&
        obj[key] !== null &&
        Object.keys(obj[key].toString()).length !== 0
      ) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        newObj[key] = obj[key];
      }
    });
    return newObj;
  };
  let newObj = obj;
  while (result) {
    result = false;
    newObj = removeEmptyProps(newObj);
    areEmptyProps(newObj);
  }
  return newObj;
};

const removeEventInfo = (obj: AnalyticsObj): void => {
  // eslint-disable-next-line no-param-reassign
  obj.eventInfo = [];
};

const mock = {
  default: {} as AnalyticsObj,
  isLive: false,
  setDefaults: function setDefaults(obj: AnalyticsObj): void {
    this.default = merge({}, this.default, obj) as AnalyticsObj;
    window.adobeDataLayer.push(this.default);
  },
  push: function push(obj: AnalyticsObj): void {
    // clean object
    const cleanObj = checkAndRemoveEmptyProps(obj);
    // remove EventInfo
    const prevDefault = { ...this.default };
    removeEventInfo(prevDefault);

    this.default = merge({}, prevDefault, cleanObj) as AnalyticsObj;

    window.adobeDataLayer.push(this.default);
  },
};

const real = {
  ...mock,
  isLive: true,
};

let implementation = mock // eslint-disable-line

if (process.env.REACT_APP_ANALYTICS) {
  implementation = real;
}

// const cleanObj = {
//   core: {
//     stagingEnvironment: null,
//     dataLayerVersion: null,
//     pageInfo: {
//       pageName: null,
//       intendedCustomerDeviceType: null,
//       version: null,
//       releaseDate: null,
//       language: null,
//       market: null,
//       publisher: null,
//     },
//     category: {
//       primaryCategory: null,
//       secondaryCategory: null,
//       productVariants: [
//         {
//           name: null,
//         },
//       ],
//       siteType: null,
//       maturityLevel: null,
//     },
//     attributes: {
//       journeyType: null,
//       viewChange: null,
//       brand: null,
//     },
//   },
//   error: {
//     errorCode: null,
//     errorMessage: null,
//     errorCausingURL: null,
//   },
//   product: [
//     {
//       category: null,
//       name: null,
//       productVariants: [],
//       productAddons: [],
//       attributes: {
//         typeOfSale: null,
//         paymentFrequency: null,
//         currency: null,
//         recurringPayment: null,
//         duration: null,
//         durationUnit: null,
//         startDateOfContract: null,
//         endDateOfContract: null,
//         paymentType: null,
//         contractAmount: null,
//         deductible: null,
//         addOns: [
//           {
//             name: null,
//             additionalInformation: null,
//           },
//         ],
//       },
//       vehicleModel: [
//         {
//           manufacturer: null,
//           name: null,
//           descriptionLong: null,
//           bodyType: null,
//           initialRegistrationDate: null,
//           currency: null,
//           endPrice_localCurrency: null,
//           typeOfUse: null,
//           condition: null,
//           engine: {
//             fuelType: null,
//             emission: null,
//             enginePower: null,
//           },
//         },
//       ],
//     },
//   ],
//   design: {
//     browserResolutionBreakpoint: getBrowserResolutionBreakpointString(
//       window.innerWidth
//     ),
//   },
//   customerData: {
//     loginStatus: false,
//     loggedInUserGroup: null,
//     yearOfBirth: null,
//     gender: null,
//     address: {
//       zipCode: null,
//     },
//   },
//   dataPrivacyStatement: {
//     allowPostalAds: null,
//     allowPhoneAds: null,
//     allowElectronicAds: null,
//     allowMailAds: null,
//     allowElectronicInvoices: null,
//   },
//   eventInfo: [
//     {
//       eventType: null,
//       eventAction: null,
//       eventTargetURL: null,
//       linkInformation: null,
//     },
//   ],
//   event: null,
// };

export { implementation as Analytics };
