import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { continueLaterLocalization } from '@vwfs-its/sf-sac-frontend';
import { getBrandName } from '../../config';

const BRAND_NAME = getBrandName();
const localization = continueLaterLocalization({
  locale: 'pt',
  brandName: { BRAND_NAME },
  dataProtectionLink: 'http://google.com',
});

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {},
    lng: 'pt',
    keySeparator: '.',
    interpolation: {
      escapeValue: false,
    },
  });
i18n.addResourceBundle('pt', 'sf-sac', localization, true, true);

export default (brandName: string): Promise<any> => {
  return new Promise((res) => {
    const namespaces: Array<string> = [
      'app',
      'landingPage',
      'simulationPage',
      'activeSCDetails',
      'productPage',
      'errorPage',
      'linkExpiredPage',
      'subscriptionPage',
      'paymentPage',
      'completionPage',
      'validation',
      'shoppingCart',
    ];
    Promise.all(
      namespaces.map((item) => {
        return import(`../../resources/base/content/${item}.json`)
          .then((module) => {
            i18n.addResourceBundle('pt', item, module.default, true, true);
          })
          .then(() => {
            /**
             * Request brand-specific resources and override (using deep) the
             * resources for specific brand. Make sure to chain those to avoid
             * race conditions.
             */
            import(`../../resources/${brandName}/content/${item}.json`)
              .then((module) => {
                i18n.addResourceBundle('pt', item, module.default, true, true);
              })
              .catch(() => {});
          });
      })
    ).then(res);
  });
};
