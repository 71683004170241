import { createBrowserHistory } from 'history';

const history = createBrowserHistory({
  basename: process.env.REACT_APP_BASE_PATH,
});

interface QueryParameters {
  [key: string]: string;
}

function qs(queryString: string, parameter: string): string {
  const strToMap: QueryParameters = queryString
    .slice(1)
    .split('&')
    .reduce((acc, item) => {
      const [name, ...value] = item.split('=');
      return {
        ...acc,
        [name]: value.join('='),
      };
    }, {});
  return strToMap[parameter] === undefined ? '' : strToMap[parameter];
}

export { history, qs };
